import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import RedeemVoucher from './redeem-voucher.vue';
let Home = class Home extends Vue {
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
};
Home = tslib_1.__decorate([
    Component({
        components: {
            RedeemVoucher,
        },
    })
], Home);
export default Home;

import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import DialogRedeemVoucher from './dialog-redeem-voucher.vue';
import { URLS } from '@/common/js/constant/index';
let RedeemVouncher = class RedeemVouncher extends Vue {
    constructor() {
        super(...arguments);
        this.redeemCode = 'KLK';
        this.isShowErrorAlert = false;
        this.errorAlertText = '';
        this.isVerifyLoading = false;
        this.isShowRedeemDialog = false;
        this.voucherInfo = {};
    }
    verifyVoucher() {
        if (!this.redeemCode) {
            return;
        }
        this.isVerifyLoading = true;
        this.$ajax.get(URLS.getVoucherInfo, {
            voucher_code: this.redeemCode,
        }, false).then((res) => {
            this.voucherInfo = res;
            this.isShowRedeemDialog = true;
        }).catch((err) => {
            this.errorAlertText = err;
            this.isShowErrorAlert = true;
        }).finally(() => {
            this.isVerifyLoading = false;
        });
    }
};
RedeemVouncher = tslib_1.__decorate([
    Component({
        components: {
            DialogRedeemVoucher,
        },
    })
], RedeemVouncher);
export default RedeemVouncher;
